import React, { Component } from 'react';
import { FadeIn } from "react-lazyload-fadein";
import axios from 'axios';
 

class Perfil extends Component {

    state = {
        perfil: []
    }


    componentDidMount() {



        axios.get(`perfiles.json`)
            .then(res => {
                const perfil = res.data;
                this.setState({ perfil });
            })

 

    }


    render() {


        

        return (

            <>


                <div class="container tit_luz">
                    <div className="row text-center">
                        <h3>Hay más luz que sombra</h3>
                        <p className="textbold">Conoce el talento que hace que las cosas cambien</p>
                    </div>
                </div>
                {Object.keys(this.state.perfil).map((key, perfiles) => (
                    
                    <><p id={this.state.perfil[key].id}></p>
                        <section className="bloque2 mt-30 efect" id="sectionperfil">

                            <div className="container">

                                <div  className="row middle mt-30">

                                    <div className="col">
                                        <h1>
                                            {this.state.perfil[key].nombre}
                                        </h1>

                                        <p className="subtitulo">
                                            {this.state.perfil[key].subtitulo}
                                        </p>
                                        <FadeIn>
                                            {onload => (
                                                <img onLoad={onload} className="bloque2-col-img movil" src={this.state.perfil[key].foto} />
                                            )}
                                        </FadeIn>

                                        <p className="text">

                                            {this.state.perfil[key].descripcion}
                                        </p>
                                    </div>

                                    <div className="col">
                                        <FadeIn duration={100} easing={'ease-out'}>
                                            {onload => (
                                                <img onLoad={onload} className="bloque2-col-img nomovil" src={this.state.perfil[key].foto} />
                                            )}
                                        </FadeIn>
                                    </div>

                                </div>



                            </div>

                        </section>


                        <section className="bloque3 mt-30 efect">

                            <div className="grid-auto">


                                <div className="item-grid">

                                    <span>{this.state.perfil[key].titulo}</span>

                                </div>


                                <div className="item-grid">

                                    <span className="outline">{this.state.perfil[key].titulo}</span>

                                </div>


                                <div className="item-grid">

                                    <span>{this.state.perfil[key].titulo}</span>

                                </div>


                                <div className="item-grid">

                                    <span className="outline">{this.state.perfil[key].titulo}</span>

                                </div>


                                <div className="item-grid">

                                    <span>{this.state.perfil[key].titulo}</span>

                                </div>


                                <div className="item-grid">

                                    <span className="outline">{this.state.perfil[key].titulo}</span>

                                </div>


                                <div className="item-grid">

                                    <span>{this.state.perfil[key].titulo}</span>

                                </div>


                                <div className="item-grid">

                                    <span className="outline">{this.state.perfil[key].titulo}</span>

                                </div>


                                <div className="item-grid">

                                    <span>{this.state.perfil[key].titulo}</span>

                                </div>




                            </div>

                        </section>


                        <section className="bloque4 mt-30 efect">

                            <div className="video">

 
                            <iframe frameborder="0" allowfullscreen allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="1920" height="720" src={`https://www.youtube.com/embed/${this.state.perfil[key].video}?autoplay=0&amp;mute=1&amp;autohide=1&amp;modestbranding=1&amp;rel=0&amp;showinfo=0&amp;controls=1&amp;disablekb=1&amp;enablejsapi=1&amp;iv_load_policy=3&amp;loop=1&amp;playlist=${this.state.perfil[key].video}&amp;widget_referrer=walmartchile.cl&amp;origin=https%3A%2F%2Fcdpn.io&amp;widgetid=1&amp;hd=1`}></iframe>
                            

                            </div>


                            <div className="subtop">

<div className="grid_img_subtop">
    <img className="subtop_hash" src="./images/hashtah.svg" alt="#TALENTOPORDELANTE" />
</div>


<div className="grid_img_subtop">
    <img className="subtop_1ko" src="./images/1ko.svg" alt="#TALENTOPORDELANTE" />
</div>


<div className="grid_img_subtop">
    <img className="subtop_hash" src="./images/hashtah-outline.svg" alt="#TALENTOPORDELANTE" />
</div>


<div className="grid_img_subtop">
    <img className="subtop_hash" src="./images/hashtah.svg" alt="#TALENTOPORDELANTE" />
</div>


<div className="grid_img_subtop">
    <img className="subtop_1ko" src="./images/1ko.svg" alt="#TALENTOPORDELANTE" />
</div>


<div className="grid_img_subtop">
    <img className="subtop_hash" src="./images/hashtah-outline.svg" alt="#TALENTOPORDELANTE" />
</div>


<div className="grid_img_subtop">
    <img className="subtop_hash" src="./images/hashtah.svg" alt="#TALENTOPORDELANTE" />
</div>


<div className="grid_img_subtop">
    <img className="subtop_1ko" src="./images/1ko.svg" alt="#TALENTOPORDELANTE" />
</div>


<div className="grid_img_subtop">
    <img className="subtop_hash" src="./images/hashtah-outline.svg" alt="#TALENTOPORDELANTE" />
</div>

</div>

                        </section>


                    </>
                ))}
 
                <section className="bloque5 mt-30 efect" id="sectiontalento">

                    <div className="container">


                        <div className="row">

                            <div className="col">
                                <h2>
                                    #TALENTOPORDELANTE
                                 </h2>


                                <p className="text">

                                    Sube una foto en Instagram con tu producto 1ko Lider
                                    utiliza los hashtag de la campaña y aparece en nuestra
                                    galería social.

                                    


            </p>

                                <p className="text">
                                    #TalentoPorDelante #HayMásLuzQueSombra #1koLider #ivoSko
                                    #Anion #Dibek #3Zetas #FabrizioAlday
            </p>

                            </div>


                            <div className="col">

                                <FadeIn duration={100} easing={'ease-out'}>
                                    {onload => (

                                        <div className="galeria">

                                            <a href="./images/galeria/1.jpg" data-lightbox="image">
                                                <img onLoad={onload} src="./images/galeria/1.jpg" alt="#TALENTOPORDELANTE" />
                                            </a>
                                            <a href="./images/galeria/2.jpg" data-lightbox="image">
                                                <img onLoad={onload} src="./images/galeria/2.jpg" alt="#TALENTOPORDELANTE" />
                                            </a>
                                            <a href="./images/galeria/3.jpg" data-lightbox="image">
                                                <img onLoad={onload} src="./images/galeria/3.jpg" alt="#TALENTOPORDELANTE" />
                                            </a>
                                            <a href="./images/galeria/4.jpg" data-lightbox="image">
                                                <img onLoad={onload} src="./images/galeria/4.jpg" alt="#TALENTOPORDELANTE" />
                                            </a>
                                            <a href="./images/galeria/5.jpg" data-lightbox="image">
                                                <img onLoad={onload} src="./images/galeria/5.jpg" alt="#TALENTOPORDELANTE" />
                                            </a>
                                            <a href="./images/galeria/6.jpg" data-lightbox="image">
                                                <img onLoad={onload} src="./images/galeria/6.jpg" alt="#TALENTOPORDELANTE" />
                                            </a>
                                            <a href="./images/galeria/7.jpg" data-lightbox="image">
                                                <img onLoad={onload} src="./images/galeria/7.jpg" alt="#TALENTOPORDELANTE" />
                                            </a>
                                            <a href="./images/galeria/8.jpg" data-lightbox="image">
                                                <img onLoad={onload} src="./images/galeria/8.jpg" alt="#TALENTOPORDELANTE" />
                                            </a>
                                            <a href="./images/galeria/9.jpg" data-lightbox="image">
                                                <img onLoad={onload} src="./images/galeria/9.jpg" alt="#TALENTOPORDELANTE" />
                                            </a>

                                        </div>

                                    )}
                                </FadeIn>

                            </div>


                        </div>



                    </div>

                </section>




            </>


        );

    }


}

export default Perfil;