import Headertema from './modulos/header';
import Footertema from './modulos/footer';
import Perfil from './modulos/perfil';
 
function App() {
  return (
     <>
 
          <Headertema/>
          <Perfil/>
          <Footertema/>
 
     </>
  );
}

export default App;
