import React, {Component} from 'react';
import { ExternalLink } from 'react-external-link';
class Footertema extends Component {

    youtube(){
        window.location.href = "https://google.com/contact";
 
  
    }

    render(){


        return(

            <>
            <footer class="bloque5 mt-30">
            <div class="container">
    
                <div class="logo">
                    <img width="120px;" src="./images/logo.svg" />
                </div>
    
                <div class="sociel_media">
    
                    <ul>
                        <li>
                        <ExternalLink  href="https://www.facebook.com/lidercl" className="icon-facebook">
                        <span>Facebook</span>
                        </ExternalLink>
    
                      
                        </li>
                        <li>
                        <ExternalLink  href="https://www.instagram.com/lider_cl/" className="icon-instagram">
                        <span>Instagram</span>
                        </ExternalLink>
     
                        </li>
                        <li>
                        <ExternalLink  href="https://twitter.com/LIDERcl" className="icon-twitter">
                        <span>Twitter</span>
                        </ExternalLink>
    
                            
                        </li>
                        <li>

                        <ExternalLink  href="" onClik={this.youtube}  className="icon-youtube">
                         <span>Youtube</span>
                        </ExternalLink>
    
                          
                        </li>
                    </ul>
    
                </div>
    
            </div>
        </footer>

        </>

        );

    }
}

export default Footertema;