import React, { Component } from 'react';
import axios from 'axios';
 
class Headertema extends Component  {


    state = {
       perfil: []
    }


    

    componentDidMount() {


let scrollpos = window.scrollY
const header = document.querySelector(".top")
const header_height = header.offsetHeight

const add_class_on_scroll = () => header.classList.add("fade-in")
const remove_class_on_scroll = () => header.classList.remove("fade-in")

window.addEventListener('scroll', function() { 
  scrollpos = window.scrollY;

  if (scrollpos >= header_height) { add_class_on_scroll() }
  else { remove_class_on_scroll() }

  console.log(scrollpos)
})





  axios.get(`perfiles.json`)
  .then(res => {
    const perfil = res.data;
    this.setState({ perfil });
  })
 

      }


    menumovil() {
        var element = document.getElementById("movil");
        element.classList.toggle("act");
     }
     
    cerrar(){
       var element = document.getElementById("movil");
       element.classList.remove("act");
     }
 
    render() {

  
        return (

            <>
                <header>

                    <nav className="mainMenu" id="movil">

                        <a className="close" id="close" href="#" onClick={this.cerrar}>x</a>

                        <ul>


                        {Object.keys(this.state.perfil).map((key, perfiles) => (
                        <li>
                        <a onClick={this.cerrar} href={"#" + this.state.perfil[key].id}>
                        {this.state.perfil[key].nombre}
                        </a>

                        </li>
                         ))}
                        </ul>
                    </nav>

                    <div className="top">

                        <div className="container">


                            <div className="ko">
                                <img width="45px;" src="./images/1ko.svg" alt="#TALENTOPORDELANTE" />
                            </div>

                            <div className="logo">
                                <img src="./images/logo.svg" alt="#TALENTOPORDELANTE" />
                            </div>


                            <a href="#" onClick={this.menumovil} className="menuBtn">
                                <span className="lines"></span>
                            </a>

                        </div>


                    </div>

                    <div className="subtop">

                        <div className="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_1ko" src="./images/1ko.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah-outline.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_1ko" src="./images/1ko.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah-outline.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_1ko" src="./images/1ko.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah-outline.svg" alt="#TALENTOPORDELANTE" />
                        </div>

                    </div>
                </header>


                <section className="bloque1 efect">

                    <div className="video">

                    <iframe id="yt-player" frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="1920" height="720" src="https://www.youtube.com/embed/StTM0KLmsZw?autoplay=1&amp;mute=1&amp;autohide=1&amp;modestbranding=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;disablekb=1&amp;enablejsapi=1&amp;iv_load_policy=3&amp;loop=1&amp;playlist=StTM0KLmsZw&amp;widget_referrer=walmartchile.cl&amp;origin=https%3A%2F%2Fcdpn.io&amp;widgetid=1&amp;hd=1"></iframe>

           
                    </div>
 

                </section>



                <section className="bloque_black">
                    <div className="container">

                        <div className="row">

                            <div className="col m-logos-c">

                                <div className="logo">
                                    <img src="./images/logos.svg" alt="#TALENTOPORDELANTE" />
                                </div>
                            </div>

                            <div className="col">

                                <h2>El talento por delante</h2>
                                <p>
                                    El proyecto 1ko busca la reinterpretación de un territorio que se relaciona con la oscuridad, dándole luz y valor a través de la exhibición del trabajo artísitco manual de los distintos talentos que coexisten como una sola gran colmena en el territorio de Bajos de Mena.
                    </p>
                            </div>

                        </div>


                    </div>
                </section>

                <section className="sep_logos">
                    <div className="subtop">

                        <div className="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_1ko" src="./images/1ko.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah-outline.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_1ko" src="./images/1ko.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah-outline.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div class="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div class="grid_img_subtop">
                            <img className="subtop_1ko" src="./images/1ko.svg" alt="#TALENTOPORDELANTE" />
                        </div>


                        <div className="grid_img_subtop">
                            <img className="subtop_hash" src="./images/hashtah-outline.svg" alt="#TALENTOPORDELANTE" />
                        </div>
 

                    </div>
                </section>

            </>

        );

    }
}

export default Headertema;